"use client";

import { useEffect } from "react";

export default function LoadingIndicator() {
  useEffect(() => {
    // console.log("LoadingIndicator mounted");
    document.body.classList.add("overflow-hidden");

    return () => {
      // console.log("LoadingIndicator unmounted");
      document.body.classList.remove("overflow-hidden");
    };
  }, []);
  return (
    <div className="fixed z-50 left-0 top-0 flex size-full items-center justify-center bg-black bg-opacity-50 text-white">
      <div>Loading...</div>
    </div>
  );
}
